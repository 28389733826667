import { lazy } from "react";
const TermsAndConditions = lazy(() => import("../../pages/terms"));
const PrivacyPolicy = lazy(() => import("../../pages/privacy"));
const Careers = lazy(() => import("../../pages/careers"));
const OurStory = lazy(() => import("../../pages/ourStory"));
const Blog = lazy(() => import("../../pages/blogs"));
const Home = lazy(() => import("../../pages/home"));
const Oraytiqoralcare = lazy(() => import("../../pages/ortiq/index"));
const BookDentalService = lazy(() => import("../../pages/bookDentalService"));

const Routes = [
  { path: "/terms&Conditions", page: TermsAndConditions },
  { path: "/privacyPolicy", page: PrivacyPolicy },
  { path: "/careers", page: Careers },
  { path: "/ourStory", page: OurStory },
  { path: "/blog", page: Blog },
  { path: "/bookDentalService", page: BookDentalService },
  { path: "/oraytiqoralcare", page: Oraytiqoralcare },
  { path: "/", page: Home },
];
export default Routes;
