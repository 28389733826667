import React from "react";
import "./stickySideBar.css";
import TwitterIcon from "../../assets/images/twitterIcon.png";
import FacebookIcon from "../../assets/images/facebookIcon.png";
import InstagramIcon from "../../assets/images/instagramIcon.png";
import YouTubeIcon from "../../assets/images/youtubeIcon.png";

const StickySideBar = () => {
  return (
    <div className="icon-bar">
      <div>
        <a
          href="https://twitter.com/DentaMitra"
          rel="noreferrer"
          target="_blank"
        >
          <div className="social-img">
            <i aria-hidden="true">
              <img src={TwitterIcon} alt="Twitter" />
            </i>
          </div>
        </a>
        <a
          href="https://www.facebook.com/dentamitra/"
          rel="noreferrer"
          target="_blank"
        >
          <div className="social-img">
            <i aria-hidden="true">
              <img src={FacebookIcon} alt="Facebook" />
            </i>
          </div>
        </a>
        <a
          href="https://www.instagram.com/dentamitraofficial/"
          rel="noreferrer"
          target="_blank"
        >
          <div className="social-img">
            <i aria-hidden="true">
              <img src={InstagramIcon} alt="Instagram" />
            </i>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UC_zOQb6TDUyvukdSs3B58Ng"
          rel="noreferrer"
          target="_blank"
        >
          <div className="social-img">
            <i aria-hidden="true">
              <img src={YouTubeIcon} alt="YouTube" />
            </i>
          </div>
        </a>
      </div>
    </div>
  );
};

export default StickySideBar;
