import React, { Suspense } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Routes from "./routes";
import { Spin } from "antd";

const history = createBrowserHistory();

function RoutesPage() {
  return (
    <Router history={history}>
      <Suspense
        fallback={<Spin size="large" style={{ margin: "20% 50%" }} />}
        style={{ display: "flex" }}
      >
        <Switch>
          {Routes.map((routeObj, routeObjIndex) => {
            return (
              <Route
                path={routeObj.path}
                key={routeObjIndex}
                exact
                render={(routerProps) => (
                  <routeObj.page
                    key={
                      routerProps.match.params[routeObj.key] || routeObjIndex
                    }
                  />
                )}
              />
            );
          })}
        </Switch>
      </Suspense>
    </Router>
  );
}
export default RoutesPage;
