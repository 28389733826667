import React, { Fragment, useEffect } from "react";
import TawkTo from "tawkto-react";
import Routes from "./core/routes";
import StickySideBar from "./components/stickySideBar/index";
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
const App = () => {
  const tawkToPropertyId = "5df33c82d96992700fcc172f";

  const tawkToKey = "default";
  useEffect(() => {
    TawkTo(tawkToPropertyId, tawkToKey);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Fragment>
      <StickySideBar />
      <Routes />
    </Fragment>
  );
};

export default App;
